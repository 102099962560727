import axios from '../instance'
import { IGetRewardList, IGetRewardRedeemedHistoryList, IReward, IRewardList, IRewardRedeem, IAfterRewardRedeemedHistory, IRewardRedeemedHistoryList, IRequestVoucher } from './reward.type'

export const getRewardList = async (params: IGetRewardList): Promise<IRewardList> => {
	const { data } = await axios.get<IRewardList>('/rewards', { params })
	return data
}

export const getRewardOne = async (rewardId: string, shopId: string): Promise<IReward> => {
	const { data } = await axios.get<IReward>(`/rewards/${rewardId}`, { params: { shopId } })
	return data
}

export const redeemReward = async (data: IRewardRedeem): Promise<IAfterRewardRedeemedHistory> => {
	const response = await axios.post<IAfterRewardRedeemedHistory>('/rewards/redeem', data)
	return response.data
}

export const getRedeemedHistoryList = async (params: IGetRewardRedeemedHistoryList): Promise<IRewardRedeemedHistoryList> => {
	const { data } = await axios.get<IRewardRedeemedHistoryList>('/rewards/redeemed/history', { params })
	return data
}

export const getVoucherList = async (params: IRequestVoucher): Promise<any> => {
	const { data } = await axios.get('/rewards/redeemed/vouchers', { params })
	return data
}

export interface IRewardImage {
    isCoverImage: boolean,
    _id: string,
    url: string,
}

export interface IReward {
    available: number,
    categories: string[],
    createdAt: string,
    updatedAt: string,
    deleted: boolean,
    deletedAt?: string,
    name: string,
    description: string,
    points: number,
    shopLimitRedeem: number,
    startDate: string,
    endDate: string,
    _id: string,
    imageUrls: IRewardImage[],
    shopRedeemedCount: number,
}

export interface IRewardList {
    data: IReward[],
	pagination: {
		pages: number;
		totals: number;
	}
}

export interface IRewardRedeem {
    shopId: string,
    rewardId: string,
}

export interface IGetRewardList {
    offset?: number,
    limit?: number,
    name?: string,
    category?: string,
    shopId?: string,
}

export enum IRewardErrorCode {
    // Before start time, After end time, No amount
    REWARD_NOT_AVAILABLE = 'REWARD_NOT_AVAILABLE',
    REWARD_REDEEM_LIMIT_EXCEEDED = 'REWARD_REDEEM_LIMIT_EXCEEDED',
    INSUFFICIENT_POINTS = 'INSUFFICIENT_POINTS',
}

export interface IRewardError {
    resCode: number,
    resDesc: IRewardErrorCode,
}

export interface IAfterRewardRedeemedHistory {
    createdAt: string,
    updatedAt: string,
    deletedAt?: string,
    deleted: boolean,
    pointsAfterRedeem: number,
    pointsBeforeRedeem: number,
    redeemedAt: string,
    redeemedBy: string,
    rewardId: string,
    rewardPoints: number,
    shopId: string,
    _id: string,
}

export type IRewardRedeemedHistory = Omit<IAfterRewardRedeemedHistory, 'rewardId'> & { rewardId: IReward }

export interface IRewardRedeemedHistoryList {
    data: IRewardRedeemedHistory[],
	pagination: {
		pages: number;
		totals: number;
	}
}

export interface IGetRewardRedeemedHistoryList {
    offset?: number,
    limit?: number,
    shopId?: string,
}

export interface IRequestVoucher {
    offset: number,
    limit: number,
    shopId: string,
}